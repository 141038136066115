* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.clearfix {
  overflow: auto;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}