.main-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #6f95e7;
  background-image: url('../images/emoji.png');
  background-repeat: repeat-x;
  background-position: bottom;
}
.main-profile-logo {
  height: 240px;
  /* border-radius: 50%; */
  margin-top: 50px;
}
.main-username {
  color: #272727;
  font-size: 45px;
  margin: 10px 0;
  font-family: 'Dancing Script', cursive;
}
.social-link {
  color: #272727;
  margin: 0 3px;
}
.social-link:hover {
  color: #8b8a8a;
}
.icon {
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .icon {
    font-size: 12px;
  }
}